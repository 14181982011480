import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
  type DesktopDatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { frFR } from '@mui/x-date-pickers/locales'
import type { CalendarDate } from '@orus.eu/calendar-date'
import { PARIS, calendarDateLt, calendarDateToDateTime } from '@orus.eu/calendar-date'
import { Avatar } from '@orus.eu/pharaoh'
import { DateTime } from 'luxon'
import { memo, useCallback, useMemo, useState } from 'react'
import { locale } from '../../lib/i18n'
import { useLargeScreen } from '../../lib/use-large-screen'

export type CalendarDateFieldProps = {
  calendarDate?: CalendarDate | null
  onChange: (newValue: CalendarDate | null) => void
  minDate?: CalendarDate
  disabled?: boolean
  error?: string
  autoFocus?: boolean
  size?: 'small' | 'large'
}

const format = 'dd/MM/yyyy'
const inputFormatDisplay = 'JJ/MM/AAAA'

/**
 * @deprecated use the component from pharaoh
 */
export const CalendarDateField = memo(function CalendarDateField(props: CalendarDateFieldProps): JSX.Element {
  const largeScreen = useLargeScreen()
  const [validationActive, setValidationActive] = useState(false)
  const { calendarDate, onChange, disabled, minDate, autoFocus, size = 'large' } = props
  const externalErrorMessage = props.error
  const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(null)
  const value = calendarDate
    ? DateTime.local(calendarDate.year, calendarDate.oneBasedMonth, calendarDate.oneBasedDay, 12)
    : null

  const setValue = useCallback(
    (date: DateTime | null) => {
      // The DatePicker component from MUI may send invalid dates during typing.
      // Since CalendarDate is not supposed to store invalid values, we prefer to send null values to the upper component
      if (!date) {
        onChange(null)
        setInternalErrorMessage(null)
        return
      }

      if (!date.isValid) {
        onChange(null)
        setInternalErrorMessage(`Utilisez le format ${inputFormatDisplay}`)
        return
      }

      const selectedCalendarDate: CalendarDate = {
        year: date.year,
        oneBasedMonth: date.month,
        oneBasedDay: date.day,
      }
      if (minDate && calendarDateLt(selectedCalendarDate, minDate)) {
        const minDateValue = DateTime.local(minDate.year, minDate.oneBasedMonth, minDate.oneBasedDay, 12)
        const formattedDate = minDateValue.toFormat(format)
        onChange(null)
        setInternalErrorMessage(`Sélectionnez le ${formattedDate} ou une date ultérieure`)
        return
      }

      onChange(selectedCalendarDate)
      setInternalErrorMessage(null)
    },
    [minDate, onChange],
  )

  const errorMessage = validationActive ? internalErrorMessage || externalErrorMessage : null

  const datePickerProps: DesktopDatePickerProps<DateTime> = useMemo(
    () => ({
      slots: {
        openPickerIcon: OpenPickerIcon,
      },
      disabled,
      value,
      onChange: (newValue) => {
        setValue(newValue)
      },
      format,
      slotProps: {
        textField: {
          fullWidth: true,
          error: !!errorMessage,
          helperText: errorMessage,
          onBlur: () => setValidationActive(true),
          inputProps: {
            placeholder: inputFormatDisplay,
          },
          autoFocus,
          // Orus size is "large" or "small" but Mui size is "medium" or "small"
          size: size === 'large' ? 'medium' : 'small',
        },
        inputAdornment: {
          sx: {
            marginRight: '8px',
          },
        },
      },
      minDate: minDate ? calendarDateToDateTime(minDate, PARIS) : undefined,
    }),
    [size, disabled, value, minDate, setValue, errorMessage, autoFocus],
  )

  const datePicker = useMemo(
    () => (largeScreen ? <DesktopDatePicker {...datePickerProps} /> : <MobileDatePicker {...datePickerProps} />),
    [datePickerProps, largeScreen],
  )

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={locale}
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      {datePicker}
    </LocalizationProvider>
  )
})

const OpenPickerIcon = memo(function OpenPickerIcon(): JSX.Element {
  return <Avatar icon="calendar-regular" size="30" />
})
