import type { SxProps } from '@mui/material'
import { useState } from 'react'
import type { Time } from '../../lib/time'
import { formatTime } from '../../lib/time'
import { validateTimeTextInput } from '../../lib/validation'
import { ValidatedTextField } from './validated-text-field'

type TimePickerFieldProps = {
  initialValue: Time | null
  onChange: (value: Time | null) => void
  sx?: SxProps
  // Step between two choices of minutes
  step?: number
  disabled?: boolean
}

export function TimePickerField(props: TimePickerFieldProps): JSX.Element {
  const [time, setTime] = useState<Time | null>(props.initialValue)

  function handleTimeChange(value: Time | null) {
    if (value !== null) {
      setTime(value)
      props.onChange(value)
    }
  }

  return (
    <ValidatedTextField
      disabled={props.disabled}
      initialValue={time !== null ? formatTime(time) : ''}
      validator={validateTimeTextInput}
      onChange={handleTimeChange}
      type={'time'}
      InputProps={{ inputProps: { step: 60 * (props.step ?? 1) } }}
    />
  )
}
