import { css } from '@emotion/react'
import { Box, Card, Typography } from '@mui/material'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { CalendarDate } from '@orus.eu/calendar-date'
import { calendarDateToDateTime, getCalendarDateFromTimestamp, PARIS } from '@orus.eu/calendar-date'
import {
  Button,
  colors,
  FlexSpacedColumn,
  LegacyDialog,
  PersistentNotification,
  Select,
  spaceBetweenFormFields,
  spacing,
  useAsyncCallback,
} from '@orus.eu/pharaoh'
import type { NotificationVariant } from '@orus.eu/pharaoh/src/components/callout-notification/notification/util'
import type { ProtectionStatus, ProtectionStatusChange, UpdatedProtectionStatus } from '@orus.eu/protection'
import {
  getAllowedNextStatuses,
  getProtectionStatusAtTime,
  isProtectionStatus,
  isTerminatedStatus,
} from '@orus.eu/protection'
import { useParams } from '@tanstack/react-router'
import { useReducer, useState, type Dispatch, type FormEvent } from 'react'
import ReactMarkdown from 'react-markdown'
import { trpc, trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { formatTimestamp } from '../../../../lib/format'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import type { Time } from '../../../../lib/time'
import { oneMinute, useCurrentTimestamp } from '../../../../lib/use-current-timestamp'
import { validateRequiredField } from '../../../../lib/validation'
import { CalendarDateField } from '../../../molecules/calendar-date-field'
import { CardBody } from '../../../molecules/card-body'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { SelectField } from '../../../molecules/select-field'
import { TimePickerField } from '../../../molecules/time-picker-field'
import { ValidatedTextField } from '../../../molecules/validated-text-field'
import { WithLabel } from '../../../organisms/with-label'
import { ResiliatedReasons } from './platform-constants'

const rightAlertBannerCardWidth = '450px'
const statusEditorFormMinWidth = '480px'
const midnight: Time = { hours: 0, minutes: 0 }

export default function PlatformChangeContractStatusPage(): JSX.Element {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/status' })
  const contractQuery = trpcReact.contracts.getContract.useQuery(subscriptionId)

  const contract: BackofficeContractDescription | undefined = contractQuery.data

  return contract ? <BackofficeChangeContractStatusLoadedPage contract={contract} /> : <GlobalLoadingState />
}

type ContractStatusInformationBanner = {
  title: string
  /**
   * The content string is rendered as Markdown
   */
  contentMd: string
  role: NotificationVariant
}

type ChangeContratStatusState = {
  inputSetId: string
  selectedStatus: UpdatedProtectionStatus | null
  effectDate: CalendarDate | null
  comment: string | null
  protectionHistory: ProtectionStatusChange[]
}

function getInitialPageState(contract: BackofficeContractDescription): ChangeContratStatusState {
  return {
    inputSetId: contract.subscriptionId,
    selectedStatus: null,
    effectDate: null,
    comment: null,
    protectionHistory: contract.protectionHistory,
  }
}

const protectionStatusBanners: { [Status in ProtectionStatus]: ContractStatusInformationBanner } = {
  'not-started': {
    title: 'Un contrat actif',
    role: 'info',
    contentMd:
      'Tout se passe pour le mieux, les garanties couvrent normalement le contrat du client, il est éligible à toutes les services et fonctionnalités. ',
  },
  active: {
    title: 'Un contrat actif',
    role: 'info',
    contentMd:
      'Tout se passe pour le mieux, les garanties couvrent normalement le contrat du client, il est éligible à toutes les services et fonctionnalités. ',
  },
  suspended: {
    title: 'Un contrat suspendu',
    role: 'info',
    contentMd:
      'Il est possible que le client soit pas mal en retard pour le règlement de ses factures ou que malheureusement nous devons bloquer sa couverture de garanties.\n' +
      '\n' +
      'Suspension du contrat après 40 jours sans paiement à date de l’échéance.',
  },
  terminated: {
    title: 'Un contrat résilié',
    role: 'warning',
    contentMd:
      'Ultime recours qui entraine la résiliation définitive du contrat et de ses garanties. \n' +
      'En cas d’impossibilité de recouvrement des impayés ou suite à une fraude ou d’exclusion.',
  },
}

type SelectStatusAction =
  | { type: 'select-status'; newStatus: ProtectionStatus; initialStatus: ProtectionStatus }
  | { type: 'update-comment'; newComment: string }
  | { type: 'update-effect-date'; newEffectDate: CalendarDate | null }

function changeContractStatusReducer(
  state: ChangeContratStatusState,
  action: SelectStatusAction,
): ChangeContratStatusState {
  const actionType = action.type
  switch (actionType) {
    case 'select-status':
      return {
        ...state,
        // "not-started" is the only state it's not possible to update to. So if "not-started" is selected
        // it means the user went back to the initial value of the select
        selectedStatus:
          action.newStatus === 'not-started'
            ? null
            : action.newStatus !== action.initialStatus
              ? action.newStatus
              : null,
      }
    case 'update-comment':
      return { ...state, comment: action.newComment }
    case 'update-effect-date':
      return { ...state, effectDate: action.newEffectDate }
  }
}

type BackofficeChangeContractStatusLoadedPage = {
  contract: BackofficeContractDescription
}

function BackofficeChangeContractStatusLoadedPage({ contract }: BackofficeChangeContractStatusLoadedPage): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)
  const [pageState, dispatch] = useReducer(changeContractStatusReducer, getInitialPageState(contract))
  const [terminationConfirmationModalShown, setTerminationConfirmationModalShown] = useState(false)
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'pending'>('idle')

  const initialProtectionStatus = getProtectionStatusAtTime(contract.protectionHistory, currentTimestamp)
  const initialStatus = initialProtectionStatus.currentStatus

  const { effectDate, comment, selectedStatus, inputSetId: subscriptionId } = pageState
  const navigateToContract = useNavigateTo({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId } })

  const submitEnabled =
    effectDate !== null &&
    comment !== null &&
    initialStatus !== selectedStatus &&
    selectedStatus !== null &&
    submissionStatus !== 'pending'

  const submitProtectionScheduleUpdate = useAsyncCallback(async () => {
    if (!submitEnabled) {
      return
    }

    setSubmissionStatus('pending')
    const protectionScheduleUpdate = getProtectionScheduleUpdate({
      selectedStatus,
      effectDate,
      reason: comment,
    })
    await trpc.contracts.updateProtectionSchedule.mutate({ subscriptionId, ...protectionScheduleUpdate })
    alert(`Le status du contrat a bien été modifié à '${protectionStatusLabels[selectedStatus]}'`)
    navigateToContract()
  }, [comment, effectDate, subscriptionId, navigateToContract, selectedStatus, submitEnabled])

  const handleSubmit = useAsyncCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      // if ask for contract termination, open modal to ask for confirmation
      if (selectedStatus === 'terminated') {
        setTerminationConfirmationModalShown(true)
        return
      }
      await submitProtectionScheduleUpdate()
    },
    [selectedStatus, submitProtectionScheduleUpdate],
  )

  return (
    <FlexSpacedColumn>
      <form onSubmit={handleSubmit}>
        <Header contract={contract} submitEnabled={submitEnabled} />
        <Box sx={{ display: 'flex', marginTop: spacing[90], gap: spacing[70] }}>
          <Box sx={{ flexGrow: 1 }}>
            <StatusEditorCard
              pageState={pageState}
              dispatch={dispatch}
              currentTimestamp={currentTimestamp}
              initialStatus={initialStatus}
              mutaSelected={contract.selectedProductWithQuote.mutaSelected!}
            />
          </Box>
          <Box sx={{ width: rightAlertBannerCardWidth }}>
            <InformationCard selectedStatus={selectedStatus} initialStatus={initialStatus} />
          </Box>
        </Box>
      </form>
      {terminationConfirmationModalShown && effectDate ? (
        <TerminationConfirmationModal
          terminationDate={effectDate}
          handleClose={() => setTerminationConfirmationModalShown(false)}
          handleConfirm={submitProtectionScheduleUpdate}
        />
      ) : (
        <></>
      )}
    </FlexSpacedColumn>
  )
}

type TerminationConfirmationModalProps = {
  terminationDate: CalendarDate
  handleClose: () => void
  handleConfirm: () => void
}

function TerminationConfirmationModal(props: TerminationConfirmationModalProps): JSX.Element {
  const title = 'Dernière étape avant la résiliation du contrat'
  const terminationDateTxt = formatTimestamp(calendarDateToDateTime(props.terminationDate, PARIS).toMillis())

  return (
    <LegacyDialog
      variant="backoffice"
      style="danger"
      title={title}
      onClose={props.handleClose}
      onSubmit={props.handleConfirm}
      submitLabel="Résilier le contrat"
      secondaryActionLabel="Annuler"
      onSecondaryAction={props.handleClose}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing[30] }}>
        <Typography variant="body2Semibold" component="p">{`Résiliation le ${terminationDateTxt}`}</Typography>
        <Typography variant="body2" component="p">
          La résiliation est ferme et définitive. Aucun changement sur le contrat ne sera possible après.
        </Typography>
      </Box>
    </LegacyDialog>
  )
}

type InformationCardProps = {
  selectedStatus: UpdatedProtectionStatus | null
  initialStatus: ProtectionStatus
}

function InformationCard({ selectedStatus, initialStatus }: InformationCardProps) {
  const banner = protectionStatusBanners[selectedStatus ?? initialStatus]

  if (banner === null) {
    return <></>
  }
  return (
    <PersistentNotification variant={banner.role} title={banner.title}>
      <ReactMarkdown>{banner.contentMd}</ReactMarkdown>
    </PersistentNotification>
  )
}

const protectionStatusLabels: { [Status in ProtectionStatus]: string } = {
  'not-started': 'Prochainement actif',
  active: 'Actif',
  suspended: 'Suspendu',
  terminated: 'Résilié',
}

type StatusEditorCardProps = {
  initialStatus: ProtectionStatus
  pageState: ChangeContratStatusState
  dispatch: Dispatch<SelectStatusAction>
  currentTimestamp: number
  mutaSelected: boolean
}

function StatusEditorCard({
  pageState,
  dispatch,
  currentTimestamp,
  initialStatus,
  mutaSelected,
}: StatusEditorCardProps) {
  const { selectedStatus, comment, effectDate, protectionHistory } = pageState

  const currentDate = getCalendarDateFromTimestamp(currentTimestamp, PARIS)

  const allowedStatusesForSelection = getAllowedNextStatuses(protectionHistory, currentTimestamp, mutaSelected)

  const onSubmitComment = (comment: string | null) => dispatch({ type: 'update-comment', newComment: comment ?? '' })

  return (
    <Card>
      <CardBody
        css={css`
          display: flex;
        `}
      >
        <Box sx={{ minWidth: statusEditorFormMinWidth }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spaceBetweenFormFields }}>
            <WithLabel label="Choisir le nouveau statut du contrat">
              <SelectField
                value={selectedStatus ?? initialStatus}
                values={allowedStatusesForSelection}
                placeholder="Nouveau statut du contrat"
                labels={protectionStatusLabels}
                onChange={(newStatus) => {
                  assert(isProtectionStatus(newStatus), `unexpected status ${newStatus}`)
                  dispatch({ type: 'select-status', newStatus, initialStatus })
                }}
              />
            </WithLabel>

            <Box sx={{ display: 'flex', gap: spacing[60] }}>
              <WithLabel label={"Date d'effet"}>
                <CalendarDateField
                  calendarDate={effectDate}
                  onChange={(newEffectDate) => dispatch({ type: 'update-effect-date', newEffectDate })}
                  minDate={currentDate}
                  error={effectDate === null ? 'Ce champ est obligatoire' : undefined}
                />
              </WithLabel>

              {/*This component is always disabled and points to midnight - present just for UI purposes*/}
              <WithLabel label={"Heure d'effet"}>
                <TimePickerField
                  initialValue={midnight}
                  onChange={() => {
                    /*Noop*/
                  }}
                  disabled
                />
              </WithLabel>
            </Box>
            {isTerminatedStatus(initialStatus, selectedStatus) ? (
              <WithLabel label={'Raison de résiliation'}>
                <Select
                  aria-label="Raisons de résiliation"
                  value={comment ?? ''}
                  values={Object.keys(ResiliatedReasons)}
                  onChange={onSubmitComment}
                  size="large"
                  placeholder="Raison de résiliation"
                  labels={ResiliatedReasons}
                />
              </WithLabel>
            ) : (
              <WithLabel label="Note">
                <ValidatedTextField
                  initialValue={comment ?? ''}
                  validator={validateRequiredField}
                  onChange={onSubmitComment}
                  placeholder="Descriptif"
                  multiline
                />
              </WithLabel>
            )}
          </Box>
        </Box>
      </CardBody>
    </Card>
  )
}

type HeaderProps = {
  contract: BackofficeContractDescription
  submitEnabled: boolean
}

function Header({ contract, submitEnabled }: HeaderProps) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: spacing[70] }}>
      <LeftColumnHeader assetName={contract.displayName} contractStartTimestamp={contract.versions[0].startTimestamp} />
      <RightColumnHeader submitEnabled={submitEnabled} />
    </Box>
  )
}

type GetProtectionScheduleUpdateOptions = {
  selectedStatus: UpdatedProtectionStatus
  effectDate: CalendarDate
  reason: string
}

type UpdateProtectionScheduleParams = {
  reactivationTimestamp: number | null
  suspensionTimestamp: number | null
  terminationTimestamp: number | null
  comment: string
}

function getProtectionScheduleUpdate({
  selectedStatus,
  effectDate,
  reason,
}: GetProtectionScheduleUpdateOptions): UpdateProtectionScheduleParams {
  const effectTimestamp = calendarDateToDateTime(effectDate, PARIS).toMillis()

  switch (selectedStatus) {
    case 'active':
      return {
        comment: reason,
        reactivationTimestamp: effectTimestamp,
        suspensionTimestamp: null,
        terminationTimestamp: null,
      }
    case 'suspended':
      return {
        comment: reason,
        reactivationTimestamp: null,
        suspensionTimestamp: effectTimestamp,
        terminationTimestamp: null,
      }
    case 'terminated':
      return {
        comment: reason,
        reactivationTimestamp: null,
        suspensionTimestamp: null,
        terminationTimestamp: effectTimestamp,
      }
  }
}

type RightColumnHeaderProps = {
  submitEnabled: boolean
}

function RightColumnHeader({ submitEnabled }: RightColumnHeaderProps) {
  return (
    <Box sx={{ alignSelf: 'center', display: 'flex', gap: spacing[30] }}>
      <Button type="submit" variant="primary" size="medium" disabled={!submitEnabled}>
        Valider
      </Button>
    </Box>
  )
}

type LeftColumnHeaderProps = {
  assetName: string
  contractStartTimestamp: number
}

function LeftColumnHeader({ assetName, contractStartTimestamp }: LeftColumnHeaderProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing[30] }}>
      <Typography variant="h4">Modifier le statut</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2">{assetName}</Typography>
        <Typography variant="body2" color={colors.gray[500]}>
          Créé le {formatTimestamp(contractStartTimestamp)}
        </Typography>
      </Box>
    </Box>
  )
}
