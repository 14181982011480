export const ResiliatedReasons = {
  'FA - Arrêt Activité': 'FA - Fake - Arrêt d’activité',
  'FI - Impayé': 'FI - Fake - Impayé',
  'FE - Erreur Contrat': 'FE - Fake - Erreur Contrat',
  'FL - A trouvé une autre assurance': 'FL - Fake - Trouvé une autre assurance',
  'FS - A jamais exercé (Stop)': 'FS - Fake - Jamais exercé',
  'FT - Test': 'FT - Fake - Test',
  'FAU - Autre': 'FAU - Fake - Autre raison',
  'FINS - Ne peut pas résilier son ancienne assurance': 'FINS - Fake - Impossible de résilier son ancienne assurance',
  'CA - Arrêt Activité': 'CA - Churn - Arrêt d’activité',
  'CI - Impayé': 'CI - Churn - Impayé',
  'CE - Erreur Contrat': 'CE - Churn - Erreur Contrat',
  'CL - A trouvé une autre assurance': 'CL - Churn - Trouvé une autre assurance',
  'CS - A jamais exercé (Stop)': 'CS - Churn - Jamais exercé',
  'CAU - Autre': 'CAU - Churn - Autre raison',
  'CINS - Ne peut pas résilier son ancienne assurance': 'CINS - Churn - Impossible de résilier son ancienne assurance',
  'FC - Ne peut pas être couvert': 'FC - Fake - Ne peut pas être couvert',
  'CC - Ne peut pas être couvert': 'CC - Churn - Ne peut pas être couvert',
}
